import { on } from '@core/on'
import { mount } from '@core/mount'
import { ajax } from 'rxjs/ajax'
import { Subject } from 'rxjs'
import { getInputValues } from '@core/forms'
import type { ComponentActions } from '@core/bootstrap'

/* eslint no-var: "off" */
declare var grecaptcha: any

type FormStep = 'recaptcha' | 'signature' | 'complete' | 'number-update' | 'error'

export default function (root: RootElement): ComponentActions {
  let verifiedHuman = false
  let form: HTMLFormElement
  const RECAPTCHA_PUBLIC_KEY = process.env.RECAPTCHA_PUBLIC_KEY!
  const step$ = new Subject<FormStep>()

  function handleRecaptcha () {
    grecaptcha.ready(() => {
      grecaptcha.execute(RECAPTCHA_PUBLIC_KEY, { action: 'submit' })
        .then((token: string) => {
          ajax.post(process.env.API_URL + 'recaptcha', { token }).subscribe({
            complete () {
              step$.next('signature')
            },
            error () {
              step$.next('error')
            },
          })
        })
    })
  }

  function handleSignature () {
    const values = getInputValues(form)
    ajax.post(process.env.API_URL + 'signature', values).subscribe({
      complete () { step$.next('complete') },
      error () { step$.next('error') },
    })
  }

  function handleComplete () {
    verifiedHuman = true

    const form = document.querySelector('[dk-component="signature-form"]')
    if (!form) return

    const successMessage = document.querySelector('[dk-form-success]')
    if (!successMessage) return

    const submit = form.querySelector('input[type="submit"]')
    if (!submit) return

    // hide arrow, show check while hiding text
    setTimeout(function () {
      submit.setAttribute('style', 'opacity: 1; background-image: url(https://uploads-ssl.webflow.com/616486c28e71d26d274dcd09/618d9a6a5605b32598496442_submit-success.svg);')
    }, 100)

    // then hide the form
    setTimeout(function () {
      form.setAttribute('style', 'opacity: 0; visibility: hidden;')
    }, 650)
    setTimeout(function () {
      form.setAttribute('style', 'display: none;')
    }, 1000)
    // then show the success message
    setTimeout(function () {
      successMessage.setAttribute('style', 'opacity: 1; visibility: visible;')
    }, 1250)
  }

  function handleError () {
    console.error('There was an error submitting the form')
  }

  function handleSubmit (event: Event, _: unused, target: Element) {
    event.preventDefault()
    form = target as HTMLFormElement

    if (verifiedHuman) return
    event.stopPropagation()

    step$.next('recaptcha')
  }

  function handleStepSubscription (step: FormStep) {
    switch (step) {
      case 'recaptcha':
        handleRecaptcha()
        break
      case 'signature':
        handleSignature()
        break
      case 'complete':
        handleComplete()
        break
      default:
        handleError()
    }
  }

  return {
    watch: [
      { target: step$, actions: [handleStepSubscription] },
    ],
    start: mount(root,
      on('signature-submission', handleSubmit),
    ),
  }
}
