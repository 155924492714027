export default function FormFrontend () {
  const form = document.querySelector('[dk-component="signature-form"]')
  if (!form) return
  const submit = form.querySelector('input[type="submit"]')
  if (!submit) return

  /**
   * Disable form unless it has a value to submit
   */
  // submit.setAttribute('disabled', 'true')
  // form.addEventListener('change', function () {
  //   console.log(submit.value)
  //   if (submit.value !== '' ) {
  //     submit.removeAttribute('disabled')
  //   }
  // })
}
