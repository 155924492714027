export default function HomeScroll () {
  if (!document.body.classList.contains('home')) return

  const beforeHeroSection = document.getElementById('before-hero-section')
  if (!beforeHeroSection) return
  const heroSection = document.getElementById('hero-section')
  if (!heroSection) return

  function scrollTo (element: HTMLElement) {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: element.offsetTop
    })
  }

  const beforeHeroObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        beforeHeroObserver.unobserve(beforeHeroSection)
        return
      } else {
        setTimeout( function() {
          if(entry.isIntersecting && !document.body.classList.contains('video-playing')) {
            scrollTo(heroSection)
          }
          beforeHeroObserver.unobserve(beforeHeroSection)
        }, 6000)
      }
    })
  })

  beforeHeroObserver.observe(beforeHeroSection)
}
