import { throttle } from './helpers'

export default function NavScroll () {
  let home = true
  let whenToChange = window.innerHeight

  if(!document.body.classList.contains('home')) home = false

  function getWindowHeight () {
    if (home) {
      whenToChange = window.innerHeight * 2
    } else {
      whenToChange = window.innerHeight
    }
  }

  function handleScroll () {
    let pixelsFromTop = window.scrollY
    if (pixelsFromTop! > whenToChange) {
      $('.o-navigation').addClass('navscrolled')
    } else {
      $('.o-navigation').removeClass('navscrolled')
    }
  }

  document.addEventListener('scroll', throttle(handleScroll, 100))
  window.addEventListener('resize', throttle(getWindowHeight, 100))
  getWindowHeight()
  handleScroll()
}
