import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { startDKApp } from '@core/app'
import { bootstrap } from '@core/bootstrap'

import ConvertTags from './app/modules/convertTags'
import Accordion from './app/modules/accordion'
import Anchors from './app/modules/anchors'
import BuildModals from './app/modules/buildModals'
import CurrentYear from './app/modules/currentYear'
import Dialog from './app/modules/dialog'
import Nav from './app/modules/nav'
import Videos from './app/modules/videos'
import NavScroll from './app/modules/navScroll'
import NavLine from './app/modules/navLine'
import HomeScroll from './app/modules/homeScroll'
import FormFrontend from './app/modules/formFrontend'

import SignatureForm from './app/components/SignatureForm'
// import GetSignatures from './app/components/GetSignatures'

startDKApp(() => {
  ConvertTags()
  Accordion()
  Anchors()
  BuildModals()
  CurrentYear()
  Dialog()
  Nav()
  Videos()
  NavScroll()
  NavLine()
  HomeScroll()
  FormFrontend()
  bootstrap('signature-form', [SignatureForm], {})
})

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: Number(process.env.SENTRY_SAMPLE_RATE),
})
