/* eslint-disable */
const BuildModals = () => {

  const tileTag = document.querySelectorAll('.o-seedgranttile')

  tileTag.forEach( (tile) => {
    let tileName = tile.querySelector('.a-seedgrantname')
    let tileFarm = tile.querySelector('.a-seedgrantfarm')
    let tileQuote = tile.querySelector('.a-seedgrantquote')
    let tileBio = tile.querySelector('.a-seedgrantbio')
    let tilePhoto = tile.querySelector('.a-seedgrantphoto')

    let modalName = document.querySelector('.a-sgmodalname')
    let modalFarm = document.querySelector('.a-sgmodalfarm')
    let modalQuote = document.querySelector('.a-sgmodalquote')
    let modalBio = document.querySelector('.a-sgmodalbio')
    let modalPhoto = document.querySelector('.a-sgmodalphoto')

    tile.addEventListener('click', function() {
      modalName.innerHTML = tileName?.innerHTML
      modalFarm.innerHTML = tileFarm?.innerHTML
      modalQuote.innerHTML = tileQuote?.innerHTML
      modalBio.innerHTML = tileBio?.innerHTML
      modalPhoto.src = tilePhoto?.src
    })
  })
}

export default BuildModals
