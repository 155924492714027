import { throttle } from './helpers'

export default function NavLine () {
  const currentLinks = '.w--current:not(.support-farmers-button) p'

  function moveLine (element: JQuery<HTMLElement>) {
    const myDistance = element.offset()!.left - $('.m-menu').offset()!.left
    const myWidth = element.width()
    $('.nav__line').css({'width': myWidth + 'px', 'transform': 'translateX(' + myDistance + 'px)'})
  }

  if ($(currentLinks).length > 0) {
    setTimeout(() => {
      moveLine($(currentLinks))
    }, 300)
  }

  window.addEventListener('resize', throttle( function() {
    if ($(currentLinks).length > 0) {
      moveLine($(currentLinks))
    }
  }, 100))

  $('.a-navlink').on('mouseenter', function() {
    moveLine($(this).find('p'))
  })

  $('.a-navlink').on('mouseleave', function() {
    if ($(currentLinks).length > 0) {
      moveLine($(currentLinks))
    } else {
      $('.nav__line').css('width', '0')
    }
  })
}
